import React, { useEffect, useState, useContext } from 'react';
import { HttpMethod, HttpOperations, HttpConnection } from '../util/HttpConnection';
import { ContainerFlex, TextLocalized, Card, Button, Container } from '../styled/Syled';
import { LanguageContext } from '../providers/LanguageProvider';

import { differenceInDays } from 'date-fns'
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { notificationDefault, NotificationView } from '../util/NotificationUtil';
registerLocale('es', es)

const bookDefault = {
    startDate: "",
    endDate: "",
    row: "",
    count: "",
    amount: "",
    location: {
        id: 1
    },
}

const CodeTodayView = () => {

    const textContext = useContext(LanguageContext);
    const [formData, setFormData] = useState("")

    const [isSending, setIsSending] = useState(false);
    const [notification, setNotification] = useState(notificationDefault);

    useEffect(() => {
        HttpConnection(HttpOperations.code,
            (item) => {
                setFormData(item);
            }, (error) => {
            }, HttpMethod.get);
    }, [])

    const _onchangeFrom = (e) => setFormData(e.target.value)

    const _submitForm = () => {
        if (formData !== "") {
            setIsSending(true);
            HttpConnection(`${HttpOperations.code}/${formData}`,
                (item) => {
                    setIsSending(false);
                    setNotification({
                        show: true,
                        color: "colorGreen",
                        message: "successCode"
                    })
                }, (error) => {
                    setIsSending(false);
                    setNotification({
                        ...notification,
                        show: true,
                        message: "errorCode"
                    })
                }, HttpMethod.put);

        } else {
            setIsSending(false);
            setNotification({
                ...notification,
                show: true,
                message: "errorOblData"
            })
        }
    }

    return (
        <Card width="12" flex={true} shadow={false} justify="center" align="center" color="colorGreenLight" pv="32" ph="32" radius="18px" mb="32">
            <Container width="12" lg="4" justify="center" textAlign="center" fontWeight="bold" fontSize="23px" children={<TextLocalized children="msgBookCodeToday" />} />

            <Container width="12" lg="4" ph="16">
                <Card width="12" color="colorWhite" mv="8" px="16" fontColor="colorBlueDark" fontSize="18px">
                    <input style={{ width: "100%" }} type="text" placeholder={textContext.dictionary['msgCode']} name="code" value={formData} onChange={_onchangeFrom} />
                </Card>
            </Container>

            <Container width="12" lg="4" ph="16">
                {
                    isSending ?
                        <Card shadow={false} flex={true} width="12" pv="14" color="colorBlueDark" align="center" justify="center" radius="25px" fontColor="colorWhite" fontWeight="bold" textAlign="center">
                            <div className="loader">Loading...</div>
                        </Card> :
                        <Button width="12" onclick={_submitForm} color="colorBlueDark" fontColor="colorWhite" textAlign="center" fontWeight="bold" ph="32" pv="16" children={<TextLocalized children="actionSave" />} />
                }
            </Container>

            <NotificationView noti={notification} setNoti={setNotification} />
        </Card>
    );
}

const BookCreateView = () => {

    const textContext = useContext(LanguageContext);
    const [formData, setFormData] = useState({ ...bookDefault })

    const [item, setItem] = useState();
    const [vacancy, setVacancy] = useState([]);
    const [availables, setAvailables] = useState([]);

    const [isSending, setIsSending] = useState(false);
    const [isSending2, setIsSending2] = useState(false);
    const [notification, setNotification] = useState(notificationDefault);

    const _checkAvailable = () => {
        if (formData.startDate !== "" && formData.endDate !== "") {
            setIsSending2(true);
            let filterAux = "";

            filterAux += `startDate=${formData.startDate.getTime()}&`;
            filterAux += `endDate=${formData.endDate.getTime()}&`;

            //Location default
            filterAux += `location=1`;

            HttpConnection(`${HttpOperations.available}${filterAux}`, (item) => {
                setItem(item.available);
                setVacancy(item.location.vacancy);
                setIsSending2(false)
            }, (error) => {
                console.log(error)
                setIsSending2(false)
            }, HttpMethod.get)
        }
    }

    useEffect(() => {
        setAvailables([])
    }, [vacancy])

    const _onchangeFrom = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })

        if (e.target.name === "row") {
            let auxAva = []
            for (let i = 1; i <= item[`${e.target.value}`]; i++) {
                auxAva.push(i);
            }
            setAvailables(auxAva)
        }
    }

    const _submitForm = () => {
        if (formData.startDate !== "" && formData.endDate !== "" && formData.row !== "" && formData.count !== "") {
            setIsSending(true)

            let days = differenceInDays(formData.endDate, formData.startDate) + 1;
            let price = days * formData.count * 6.5;

            let book = {
                "startDate": formData.startDate.setHours(0, 0, 0, 0),
                "endDate": formData.endDate.setHours(21, 0, 0, 0),
                "row": formData.row,
                "count": formData.count,
                "location": {
                    "id": 1
                },
                "amount": price * 100
            }

            console.log(book)

            HttpConnection(HttpOperations.bookingTmp,
                (item) => {
                    console.log(item)
                    HttpConnection(`${HttpOperations.confirm}/${item.id}`,
                        (item) => {
                            setIsSending(false);
                            setFormData(bookDefault)
                            setNotification({
                                show: true,
                                color: "colorGreen",
                                message: "successBook"
                            })
                        },
                        (error) => {
                            setIsSending(false);
                            setNotification({
                                ...notification,
                                show: true,
                                message: "errorBook"
                            })
                        }, HttpMethod.patch)
                }, (error) => {
                    setIsSending(false);
                    setNotification({
                        ...notification,
                        show: true,
                        message: "errorBook"
                    })
                }, HttpMethod.post, book)
        } else {
            setNotification({
                ...notification,
                show: true,
                message: "errorOblData"
            })
        }

    }

    return (
        <Card width="12" flex={true} shadow={false} justify="center" align="center" color="colorBlueLight" pv="32" ph="32" radius="18px">
            <ContainerFlex width="12" justify="center" fontWeight="bold" fontSize="23px" mb="16">
                <TextLocalized children="actionBook" />
            </ContainerFlex>

            <Container width="12" lg="4" ph="16">
                <Card width="12" color="colorWhite" mv="8" px="16" fontColor="colorBlack" fontSize="18px">
                    <DatePicker
                        locale="es"
                        dateFormat="dd/MM/yyyy"
                        showPopperArrow={false}
                        selected={formData.startDate}
                        onChange={(date) => setFormData({ ...formData, startDate: date })}
                        name="startDate"
                        placeholderText={textContext.dictionary['msgStartDate']}
                        className="input-date"
                    />
                </Card>
            </Container>
            <Container width="12" lg="4" ph="16">
                <Card width="12" color="colorWhite" mv="8" px="16" fontColor="colorBlack" fontSize="18px">
                    <DatePicker
                        locale="es"
                        dateFormat="dd/MM/yyyy"
                        showPopperArrow={false}
                        selected={formData.endDate}
                        onChange={(date) => setFormData({ ...formData, endDate: date })}
                        name="endDate"
                        placeholderText={textContext.dictionary['msgEndDate']}
                        className="input-date"
                    />
                </Card>
            </Container>

            <Container width="12" lg="4" px="16">
                {
                    isSending2 ?
                        <Card shadow={false} flex={true} width="12" pv="14" color="colorGreen" align="center" justify="center" radius="25px" fontColor="colorWhite" fontWeight="bold" textAlign="center">
                            <div className="loader">Loading...</div>
                        </Card> :
                        <Button width="12" onclick={_checkAvailable} color="colorGreen" fontColor="colorWhite" textAlign="center" fontWeight="bold" pv="16" children={<TextLocalized children="actionCheckAvailable" />} />

                }
            </Container>

            <Container width="12" lg="4" ph="16">
                <Card width="12" color="colorWhite" mv="8" px="16" fontColor="colorBlack" fontSize="18px">
                    <select name="row" style={{ width: "100%" }} value={formData.status} onChange={_onchangeFrom} >
                        <option value="">Selecciona fila</option>
                        {
                            vacancy.map((vacancy) => <option value={vacancy.row} key={vacancy.id}>{`${textContext.dictionary['msgRow']} ${vacancy.row} - ${item[`${vacancy.row}`]} disp.`}</option>)
                        }
                    </select>
                </Card>
            </Container>

            <Container width="12" lg="4" ph="16">
                <Card width="12" color="colorWhite" mv="8" px="16" fontColor="colorBlack" fontSize="18px">
                    <select name="count" style={{ width: "100%" }} value={formData.status} onChange={_onchangeFrom} >
                        <option value="">Selecciona hamacas</option>
                        {
                            availables.map((available) => <option value={available} key={available}>{`${available} ${textContext.dictionary['msgHamacas']}`}</option>)
                        }
                    </select>
                </Card>
            </Container>

            <Container width="12" lg="4" px="16">
                {
                    isSending ?
                        <Card shadow={false} flex={true} width="12" pv="14" color="colorBlue" align="center" justify="center" radius="25px" fontColor="colorWhite" fontWeight="bold" textAlign="center">
                            <div className="loader">Loading...</div>
                        </Card> :
                        <Button width="12" onclick={_submitForm} color="colorBlueDark" fontColor="colorWhite" textAlign="center" fontWeight="bold" pv="16" children={<TextLocalized children="actionBook" />} />

                }
            </Container>

            <NotificationView noti={notification} setNoti={setNotification} />

        </Card>
    );
}

const BookView = () => {

    return (
        <ContainerFlex width="10" lg="9" pv="32" align="center" justify="space-between">
            <CodeTodayView />
            <BookCreateView />
        </ContainerFlex>
    );
};

export default BookView;