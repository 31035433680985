import React, { useReducer } from 'react';
import { getStorageUser, saveStorageUser } from '../util/UserUtil';

const UserContext = React.createContext();
const UserConsumer = UserContext.Consumer;

export const UserData = {
    user: "SAVE_USER_MANAGER"
}

const initState = {
    login: getStorageUser() ? true : false,
}

const UserAction = {
    login: "LOGIN",
    logout: "LOGOUT",
}

const reduce = (state, callback) => {
    switch (callback.action) {
        case UserAction.login:
            saveStorageUser(callback.data);
            return { ...state, login: true };
        case UserAction.logout:
            localStorage.removeItem(UserData.user)
            return { ...state, login: false };
        default:
            return state
    }
}

const UserPovider = (props) => {
    const [state, dispatch] = useReducer(reduce, initState);
    let value = { state, dispatch };

    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    );
}

export { UserContext, UserPovider, UserConsumer, UserAction }