import React from 'react';
import { ContainerFlex, Container, TextLocalized } from '../styled/Syled';

const Footer = () => {
    return (
        <ContainerFlex width="12" pv="100" color="colorBlueDark" justify="center" align="center">
            <ContainerFlex width="11" justify="center">
                <ContainerFlex direction="column" align="center" mh="32">
                    <Container>
                        <Container fontColor="colorWhite" fontWeight="bold" fontSize="25px" children={<TextLocalized children="msgAppName" />} />
                    </Container>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Footer;