import React, { Fragment, useContext } from 'react';

import './css/normalize.css';
import 'react-datepicker/dist/react-datepicker.css';
import './css/bootstrap-grid.css';
import './css/App.css';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UserContext, UserPovider } from './components/providers/UserProvider';
import LoginView from './components/view/LoginView';
import Header from './components/view/Header';
import Footer from './components/view/Footer';
import BooksView from './components/view/BooksView';
import { LanguageProvider } from './components/providers/LanguageProvider';
import { ContainerFlex } from './components/styled/Syled';
import AvailableView from './components/view/AvailableView';
import BookView from './components/view/BookView';

const SwitchView = () => {
  const { state } = useContext(UserContext);

  return (
    <Fragment>
      {
        state.login ?
          <Router basename="/">
            <Header />
            <Switch>
              <Route exact={true} path="/" component={BooksView} />
              <Route exact={true} path="/available" component={AvailableView} />
              <Route exact={true} path="/book" component={BookView} />
            </Switch>
          </Router>
          : <LoginView />
      }
    </Fragment>
  )
}

const App = () => {
  return (
    <LanguageProvider>
      <UserPovider>
        <ContainerFlex width="12" justify="center" color="colorWhite" colorEnd="colorWhite">
          <SwitchView />
          <Footer />
        </ContainerFlex>
      </UserPovider>
    </LanguageProvider>
  )
}


export default App;
