import React, { useContext } from "react"
import styled from 'styled-components'
import { LanguageContext } from "../providers/LanguageProvider";

/* UTIL */
const widthResponsive = (attr) => {
    const { width, xs, sm, lg, xl } = attr;
    const mwidth = `${width ? `col-${width}` : ``}${xs ? ` col-xs-${xs}` : ``}${sm ? ` col-sm-${sm}` : ``}${lg ? ` col-lg-${lg}` : ``}${xl ? ` col-xl-${xl}` : ``}`;
    return mwidth;
}

const marginAndPadding = (props) => {
    const { px = 0, pt, pr, pb, pl, ph, pv } = props;
    const { mx = 0, mt, mr, mb, ml, mh, mv } = props;
    const padding = `${pt ? pt : pv ? pv : px}px ${pr ? pr : ph ? ph : px}px ${pb ? pb : pv ? pv : px}px ${pl ? pl : ph ? ph : px}px`;
    const margin = `${mt ? mt : mv ? mv : mx}px ${mr ? mr : mh ? mh : mx}px ${mb ? mb : mv ? mv : mx}px ${ml ? ml : mh ? mh : mx}px`;

    return `
        padding: ${padding};
        margin: ${margin};
    `;
}

const textFormat = (props) => {
    const { fontColor, fontWeight } = props;
    const { fontSize, fontSizeSm, fontSizeMd, fontSizeLg, fontSizeXl } = props;
    const { textAlign, textAlignSm, textAlignMd, textAlignLg, textAlignXl } = props;

    return `
        ${fontColor ? `color: var(--${fontColor});` : ``}
        ${fontWeight ? `font-weight: ${fontWeight};` : ``}
        ${fontSize ? `font-size: ${fontSize};` : ``}
        ${textAlign ? `text-align: ${textAlign};` : ``}

        @media (min-width: 576px) {
            ${fontSizeSm ? `font-size: ${fontSizeSm};` : ``}
            ${textAlignSm ? `text-align: ${textAlignSm};` : ``}
        }

        @media (min-width: 768px) {
            ${fontSizeMd ? `font-size: ${fontSizeMd};` : ``}
            ${textAlignMd ? `text-align: ${textAlignMd};` : ``}
        }

        @media (min-width: 1144px) {
            ${fontSizeLg ? `font-size: ${fontSizeLg};` : ``}
            ${textAlignLg ? `text-align: ${textAlignLg};` : ``}
        }

        @media (min-width: 1200px) {
            ${fontSizeXl ? `font-size: ${fontSizeXl};` : ``}
            ${textAlignXl ? `text-align: ${textAlignXl};` : ``}
        }
    `;
}


const displayItem = (props) => {
    const { display = "auto", displaySm = "auto", displayMd = "auto", displayLg = "auto", displayXl = "auto" } = props;
    return `
        display: ${display};

        @media (min-width: 576px) {
            display: ${displaySm};
        }

        @media (min-width: 768px) {
            display: ${displayMd};
        }

        @media (min-width: 992px) {
            display: ${displayLg};
        }

        @media (min-width: 1200px) {
            display: ${displayXl};
        }
    `
}

/* COMPONENT CONTAINER */
const FmuiContainer = styled.div`
    ${props => {
        //Set default values
        const { position = 'initial', top, rigth, bottom, left } = props.attr;
        const { height, minwidth, maxwidth, minheight, maxheight, overflowY = "initial" } = props.attr;
        const { color, colorEnd } = props.attr;
        const { elevation } = props.attr;
        const { image, imgWidth = "100%", imgHeigt = "100%" } = props.attr;

        return `
        position: ${position};
        display: block;
        ${top ? `top: ${top};` : ``}
        ${rigth ? `rigth: ${rigth};` : ``}
        ${bottom ? `bottom: ${bottom};` : ``}
        ${left ? `left: ${left};` : ``}

        ${minwidth ? `min-width: ${minwidth};` : ``}
        ${minheight ? `min-height: ${minheight};` : ``}
        ${maxwidth ? `max-width: ${maxwidth};` : ``}
        ${maxheight ? `max-height: ${maxheight};` : ``}
        ${height ? `height: ${height};` : ``}

        ${marginAndPadding(props.attr)}
        background: ${colorEnd ? `linear-gradient(var(--${color}), var(--${colorEnd}));` : `var(--${color})`};
        ${image ? `background-image: url(${image}); background-size: ${imgWidth} ${imgHeigt};` : ""}
        box-sizing: border-box;
        overflow-y: ${overflowY};
        ${elevation ? ` z-index:${elevation};` : ''}

        ${textFormat(props.attr)}
        ${displayItem(props.attr)}
    `;
    }}
`;

export const Container = (attr) => {
    const { extras, children } = attr;
    const mwidth = widthResponsive(attr);
    return (
        <FmuiContainer
            attr={attr}
            className={`${mwidth}${extras ? ` ${extras}` : ""}`}
            children={children} />
    );
}

/* COMPONENT CONTAINER-FLEX */
const FmuiContainerFlex = styled(FmuiContainer)`
    ${props => {
        //Set defaults values
        const { multiline = true } = props.attr;
        const {
            direction = "row",
            justify = "flex-start",
            align = "flex-start",
            alignContent = "auto" } = props.attr;

        const { display = "flex", displaySm = "flex", displayMd = "flex", displayLg = "flex", displayXl = "flex" } = props.attr;

        return `
        flex-wrap: ${multiline ? "wrap" : "nowrap"};
        flex-direction: ${direction};
        justify-content: ${justify};
        align-items: ${align};
        align-content: ${alignContent};

        display: ${display};

        @media (min-width: 576px) {
            display: ${displaySm};
        }

        @media (min-width: 768px) {
            display: ${displayMd};
        }

        @media (min-width: 992px) {
            display: ${displayLg};
        }

        @media (min-width: 1200px) {
            display: ${displayXl};
        }
    `;
    }}
`;

export const ContainerFlex = (attr) => {
    const { extras, children } = attr;
    const mwidth = widthResponsive(attr);
    return (
        <FmuiContainerFlex
            attr={attr}
            className={`${mwidth}${extras ? ` ${extras}` : ""}`}
            children={children} />
    );
}

/* COMPONENT CARD */
const FmuiCard = styled(FmuiContainer)`
    ${props => cardProps(props)}
`;

const FmuiCardFlex = styled(FmuiContainerFlex)`
    ${props => cardProps(props)}
`;

const cardProps = (props) => {
    const { radius = "6px", radiustl, radiustr, radiusbr, radiusbl } = props.attr;
    const { border = "none", borderWidth, borderColor } = props.attr;
    const { shadow = true } = props.attr;

    return `
            ${border !== 'none' ? `border: ${borderWidth} ${border} var(--${borderColor})` : ''};
            border-top-left-radius: ${ radiustl ? radiustl : radius};
            border-top-right-radius: ${ radiustr ? radiustr : radius};
            border-bottom-right-radius: ${ radiusbr ? radiusbr : radius};
            border-bottom-left-radius: ${ radiusbl ? radiusbl : radius};
            ${ shadow ? `box-shadow: 0 0 0 1px rgba(205,210,223,0.15), 0 6px 12px 0 rgba(205,210,223,0.3);` : ``}
        `;

}

export const Card = (attr) => {
    const { children, flex = false } = attr;
    const mwidth = widthResponsive(attr);
    return flex
        ? <FmuiCardFlex attr={attr} className={mwidth} children={children} />
        : <FmuiCard attr={attr} className={mwidth} children={children} />;
}

/* COMPONENT BUTTON */
const FmuiButton = styled(FmuiCard)`
    ${props => buttonProps(props)}
`;

const FmuiButtonFlex = styled(FmuiCardFlex)`
    ${props => buttonProps(props)}
`;

const buttonProps = (attr) => `
    cursor: pointer;
    overflow-y:hidden;
    & > label {
        cursor: pointer;
    }
`;

export const Button = (attr) => {
    const { onclick, children, flex = false } = attr;
    const mwidth = widthResponsive(attr);
    return (
        flex
            ? <FmuiButtonFlex attr={attr} onClick={onclick} className={`${mwidth}`} children={children} />
            : <FmuiButton attr={attr} onClick={onclick} className={`${mwidth}`} children={children} />
    );
}

export const TextLocalized = (attr) => {
    const languageContext = useContext(LanguageContext);
    const { children } = attr;

    const text = languageContext.dictionary[children];

    return <label dangerouslySetInnerHTML={{ __html: text }} />
}
