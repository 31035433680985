const es = {
  msgAppName: 'BeachApp',
  msgManager: 'Manager',
  msgEmail: 'Email',
  msgPassword: 'Password',


  actionLogin: 'INICIAR SESIÓN',
  actionLogout: 'Cerrar sesión',
  actionDelete: 'Eliminar',
  actionClean: 'Limpiar',
  actionBook: 'Reservar',

  msgBooks: 'Reservas',
  msgLocations: 'Playas',
  msgHamacas: 'Hamacas',
  msgToday: 'Hoy',
  msgAvailable: 'Disponibilidad',
  msgAvailables: 'disponibles',

  msgAddLocation: 'Añadir playa',
  msgAddLocker: 'Añadir taquilla',


  msgLocalidad: 'Nombre',
  msgAddress: 'Dirección',

  msgBookFilter: 'Filtro de reservas',
  msgAvailableFilter: 'Filtro de disponibilidad',

  msgDate: 'Fecha',
  msgStartDate: 'Fecha inicial',
  msgEndDate: 'Fecha final',
  msgStatus: 'Estado',

  actionSave: 'Guardar',
  actionDetails: 'Detalles',
  actionEdit: 'Editar',
  actionFilter: 'Filtrar',
  actionToday: 'Hoy',
  actionCancel: 'Cancelar',
  actionCheckAvailable: 'Comprobar disponibilidad',

  msgName: "Nombre",
  msgDateStart: "Fecha inicio",
  msgDateEnd: "Fecha fin",

  msgPrice: "Precio",

  msgRow: "Fila",
  msgCount: "Cantidad",
  msgBookCode: "Código reserva",
  msgBookCodeToday: "Código reserva diaria",
  msgCode: "Código",


  errorOblData: 'Todos los campos son obligatorios',
  errorLogin: 'Usuario y/o contraseña incorrectos',
  errorSave: 'Error al guardar los datos',
  errorBook: 'Su selección ya no se encuentra disponible, disculpe las molestias',
  errorCode: 'El código introducido es incorrecto',

  successBook: 'Reserva realizada correctamente',
  successCode: 'Código modificado correctamente',


};

export default es;

export const webLandingUrl = "https://nomadaspoint.es";