import React, { useState, useContext } from 'react';
import { ContainerFlex, Button, TextLocalized, Container } from '../styled/Syled';
import { useHistory } from 'react-router';
import ic_menu from '../../image/ic_menu.svg';
import { UserAction, UserContext } from '../providers/UserProvider';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const { dispatch } = useContext(UserContext);

    let history = useHistory();

    const _toggleResponsiveMenu = () => setShowMenu(!showMenu)

    const _handlerLogout = () => dispatch({ action: UserAction.logout });

    return (
        <ContainerFlex width="10" lg="9" pv="32" align="center" justify="space-between">
            <Container fontColor="colorGreen" fontWeight="bold" fontSize="25px" children={<TextLocalized children="msgAppName" />} />

            <Button shadow={false} height="48px" display="auto" displayLg="none" onclick={_toggleResponsiveMenu}>
                <img alt="menu" width="48" height="48" src={ic_menu} style={{ cursor: "pointer" }} />
            </Button>

            {
                showMenu ?
                    <ContainerFlex align="center" displayLg="none" mt="16" justify="center" width="12">
                        <Button width="12" onclick={() => history.push('')} shadow={false} textColor="colorBlueDark" ph="8" mh="8" pv="16" textAlign="center">
                            <TextLocalized children="msgBooks" />
                        </Button>
                        <Button width="12" onclick={() => history.push('available')} shadow={false} textColor="colorBlueDark" ph="8" mh="8" pv="16" textAlign="center">
                            <TextLocalized children="msgAvailable" />
                        </Button>
                        <Button width="12" onclick={() => history.push('book')} shadow={false} textColor="colorBlueDark" ph="8" mh="8" pv="16" textAlign="center">
                            <TextLocalized children="actionBook" />
                        </Button>
                        <Button onclick={_handlerLogout} width="12" shadow={false} textColor="colorBlueDark" ph="8" mh="8" pv="16" textAlign="center">
                            <TextLocalized children="actionLogout" />
                        </Button>
                    </ContainerFlex>
                    : null
            }

            <ContainerFlex align="center" display="none" displaySm="none" displayMd="none">
                <Button onclick={() => history.push('')} shadow={false} textColor="colorBlueDark" ph="8" mh="8">
                    <TextLocalized children="msgBooks" />
                </Button>
                <Button onclick={() => history.push('available')} shadow={false} textColor="colorBlueDark" ph="8" mh="8">
                    <TextLocalized children="msgAvailable" />
                </Button>
                <Button onclick={() => history.push('book')} shadow={false} textColor="colorBlueDark" ph="8" mh="8">
                    <TextLocalized children="actionBook" />
                </Button>
                <Button onclick={_handlerLogout} shadow={false} ph="24" pv="10" mh="8" color="colorBlueDark" radius="24px" fontColor="colorWhite" fontWeight="bold">
                    <TextLocalized children="actionLogout" />
                </Button>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Header;