import React, { useEffect, useState, useContext } from 'react';
import { HttpMethod, HttpOperations, HttpConnection } from '../util/HttpConnection';
import { ContainerFlex, TextLocalized, Card, Button, Container } from '../styled/Syled';
import { LanguageContext } from '../providers/LanguageProvider';

import { format } from 'date-fns'
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

const bookFilterDefault = {
    "startDate": new Date(),
    "endDate": new Date(),
    "status": "2"
}

const BooksFilterView = ({ filter, setFilter }) => {

    const textContext = useContext(LanguageContext);
    const [formData, setFormData] = useState({ ...filter })

    const _onchangeFrom = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const _submitForm = () => setFilter(formData)
    const _cleanFilter = () => {
        setFilter(bookFilterDefault)
        setFormData(bookFilterDefault)
    }

    return (
        <Card width="12" flex={true} shadow={false} justify="center" align="center" color="colorGreenLight" px="16" radius="18px">
            <ContainerFlex width="12" justify="center" fontWeight="bold" fontSize="23px" mb="16">
                <TextLocalized children="msgBookFilter" />
            </ContainerFlex>

            <Container width="12" lg="4" ph="16">
                <Card width="12" color="colorWhite" mv="8" px="16" fontColor="colorBlack" fontSize="18px">
                    <DatePicker
                        locale="es"
                        dateFormat="dd/MM/yyyy"
                        showPopperArrow={false}
                        selected={formData.startDate}
                        onChange={(date) => setFormData({ ...formData, startDate: date })}
                        name="startDate"
                        placeholderText={textContext.dictionary['msgStartDate']}
                        className="input-date"
                    />
                </Card>
            </Container>
            <Container width="12" lg="4" ph="16">
                <Card width="12" color="colorWhite" mv="8" px="16" fontColor="colorBlack" fontSize="18px">
                    <DatePicker
                        locale="es"
                        dateFormat="dd/MM/yyyy"
                        showPopperArrow={false}
                        selected={formData.endDate}
                        onChange={(date) => setFormData({ ...formData, endDate: date })}
                        name="endDate"
                        placeholderText={textContext.dictionary['msgEndDate']}
                        className="input-date"
                    />
                </Card>
            </Container>

            <Container width="12" lg="4" ph="16">
                <Card width="12" color="colorWhite" mv="8" px="16" fontColor="colorBlack" fontSize="18px">
                    <select name="status" placeholder="Estado" style={{ width: "100%" }} value={formData.status} onChange={_onchangeFrom} >
                        <option value="">Todas</option>
                        <option value="2">Activa</option>
                        <option value="3">Finalizada</option>
                        <option value="4">Cancelada</option>
                    </select>
                </Card>
            </Container>

            <ContainerFlex width="12" lg="12" pv="8">
                <Container width="12" lg="6" px="16">
                    <Button width="12" onclick={_submitForm} color="colorGreen" fontColor="colorWhite" textAlign="center" fontWeight="bold" pv="16" children={<TextLocalized children="actionFilter" />} />
                </Container>
                <Container width="12" lg="6" px="16">
                    <Button onclick={_cleanFilter} color="colorBlueDark" fontColor="colorWhite" textAlign="center" fontWeight="bold" pv="16" children={<TextLocalized children="actionToday" />} />
                </Container>
            </ContainerFlex>

        </Card>
    );
}

const BookItem = ({ item, sendCancel }) => {

    const [bookStatus, setBookStatus] = useState({ ...item.status })
    const textContext = useContext(LanguageContext);
    const [showDetails, setShowDetails] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const _cancel = () => {
        if (window.confirm("¿Está seguro?")) {
            setIsSending(true);

            HttpConnection(`${HttpOperations.cancel}/${item.id}`,
                (item) => {
                    setIsSending(false);
                    setBookStatus(4);
                }, (error) => {
                    setIsSending(false);
                }, HttpMethod.patch);
        }
    }

    return <Card shadow={false} width="12" color="colorBlueLight" mb="16">
        <ContainerFlex width="12" align="center" textAlign="center">
            <Container fontWeight="bold" width="12" lg="3" pv="8" ph="16" children={item.mail} />
            <Container fontWeight="bold" width="12" lg="3" pv="8" ph="16" children={`${format(item.startDate, "dd/MM/yyyy")} - ${format(item.endDate, "dd/MM/yyyy")}`} />
            <Container fontWeight="bold" width="12" lg="2" pv="8" ph="16" children={item.status.description} />

            {
                bookStatus.id !== 4 ?
                    (
                        isSending ?
                            <Card shadow={false} flex={true} width="12" pv="14" color="colorBlue" align="center" justify="center" radius="25px" fontColor="colorWhite" fontWeight="bold" textAlign="center">
                                <div className="loader">Loading...</div>
                            </Card> :
                            <ContainerFlex width="12" lg="2" px="8">
                                <Button onclick={_cancel} width="12" color="colorRed" fontColor="colorWhite" textAlign="center" fontWeight="bold" ph="32" pv="16" children={<TextLocalized children="actionCancel" />} />
                            </ContainerFlex>
                    )
                    : null
            }
            <ContainerFlex width="12" lg={bookStatus.id !== 4 ? "2" : "4"} px="8">
                <Button onclick={() => setShowDetails(!showDetails)} width="12" color="colorBlueDark" fontColor="colorWhite" textAlign="center" fontWeight="bold" ph="32" pv="16" children={<TextLocalized children="actionDetails" />} />
            </ContainerFlex>
        </ContainerFlex>
        {
            showDetails ?
                <Card flex={true} width="12" textAlign="center" pv="8" color={"colorGreenLight"} radiustl="0" radiustr="0">
                    <Container fontWeight="bold" width="12" lg="3" pv="8" ph="16" children={`${textContext.dictionary["msgRow"]}: ${item.row}`} />
                    <Container fontWeight="bold" width="12" lg="3" pv="8" ph="16" children={`${textContext.dictionary["msgHamacas"]}: ${item.count}`} />
                    <Container fontWeight="bold" width="12" lg="3" pv="8" ph="16" children={`${textContext.dictionary["msgPrice"]}: ${item.amount / 100} €`} />
                    <Container fontWeight="bold" width="12" lg="3" pv="8" ph="16" children={`${textContext.dictionary["msgBookCode"]}: ${item.code}`} />
                </Card>
                : null
        }
    </Card >
}

const BookListView = ({ books }) => {

    return <ContainerFlex width="12" justify="center">
        <ContainerFlex width="12" justify="center" fontWeight="bold" fontSize="25px" fontSizeLg="35px" mv="64" textAlign="center">
            <TextLocalized children="msgBooks" />
        </ContainerFlex>
        {books.map((item) => <BookItem key={item.id} item={item} />)}
    </ContainerFlex>
}

const BooksView = () => {
    const [items, setItems] = useState([])
    const [filter, setFilter] = useState(bookFilterDefault);

    useEffect(() => {
        const requestAPI = () => {
            let filterAux = "";

            filterAux += `startDate=${filter.startDate !== "" ? filter.startDate.setHours(0, 0, 0, 0) : new Date().setHours(0, 0, 0, 0)}&`;
            filterAux += `endDate=${filter.endDate !== "" ? filter.endDate.setHours(23, 59, 59, 0) : new Date().setHours(23, 59, 59, 0)}&`;

            //No muestra reservas temporales
            filterAux += `status=${filter.status !== "" ? filter.status : '2,3,4'}&`;

            HttpConnection(`${HttpOperations.bookingFilter}${filterAux}`, (item) => {
                console.log(item)
                setItems(item.filter(itm => itm.code !== null));
            }, (error) => console.log(error), HttpMethod.get)
        }
        requestAPI();

    }, [filter])

    return (
        <ContainerFlex width="10" lg="9" pv="32" align="center" justify="space-between">
            <BooksFilterView filter={filter} setFilter={setFilter} />
            <BookListView books={items} />
        </ContainerFlex>
    );
};

export default BooksView;