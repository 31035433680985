import React, { useState, useContext } from 'react';
import { ContainerFlex, Container, TextLocalized, Card, Button } from '../styled/Syled';
import { LanguageContext } from '../providers/LanguageProvider';
import { notificationDefault, NotificationView } from '../util/NotificationUtil';
import { HttpConnection, HttpOperations, HttpMethod } from '../util/HttpConnection';
import { UserContext, UserAction } from '../providers/UserProvider';

const loginData = {
    "username": '',
    "password": ''
}

const LoginView = () => {
    const [formData, setFormData] = useState(loginData);
    const [notification, setNotification] = useState(notificationDefault);

    const { dispatch } = useContext(UserContext);

    const textContext = useContext(LanguageContext);

    const _onchangeFrom = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const _submitForm = () => {
        if (formData.username === "" || formData.password === "") {
            setNotification({
                ...notification,
                show: true,
                message: "errorOblData"
            })
        } else {
            HttpConnection(HttpOperations.token,
                (item) => {
                    if (item.usuario.type.id === 0) {
                        item.usuario.password = formData.password;
                        dispatch({ action: UserAction.login, data: item })
                    } else {
                        setNotification({
                            ...notification,
                            show: true,
                            message: "errorLogin"
                        })
                    }
                }, (error) => {
                    setNotification({
                        ...notification,
                        show: true,
                        message: "errorLogin"
                    })
                }, HttpMethod.post, formData)
        }
    }

    return (
        <ContainerFlex width="12" minheight="100vh" justify="center" align="center" direction="column" color="colorGreenLight">

            <ContainerFlex pv="32" align="flex-end">
                <Container fontColor="colorBlueDark" fontWeight="bold" fontSize="25px" children={<TextLocalized children="msgAppName" />} />
                <Container ml="16" fontColor="colorGreen" fontWeight="bold" children={<TextLocalized children="msgManager" />} />
            </ContainerFlex>

            <Container width="12" lg="6" px="16">
                <Card width="12" color="colorWhite" mv="8" px="16" fontColor="colorBlueDark" fontSize="18px">
                    <input style={{ width: "100%" }} type="text" placeholder={textContext.dictionary['msgEmail']} name="username" value={formData.email} onChange={_onchangeFrom} />
                </Card>

                <Card width="12" color="colorWhite" mt="16" px="16" fontColor="colorBlueDark" fontSize="18px">
                    <input style={{ width: "100%" }} type="password" placeholder={textContext.dictionary['msgPassword']} name="password" value={formData.password} onChange={_onchangeFrom} />
                </Card>

                <NotificationView noti={notification} setNoti={setNotification} />

                <ContainerFlex px="32" justify="center">
                    <Button onclick={_submitForm} color="colorBlueDark" fontColor="colorWhite" fontWeight="bold" ph="32" pv="16" children={<TextLocalized children="actionLogin" />} />
                </ContainerFlex>
            </Container>


        </ContainerFlex>
    );
};

export default LoginView;