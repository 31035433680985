import React, { useEffect, useState, useContext } from 'react';
import { HttpMethod, HttpOperations, HttpConnection } from '../util/HttpConnection';
import { ContainerFlex, TextLocalized, Card, Button, Container } from '../styled/Syled';
import { LanguageContext } from '../providers/LanguageProvider';

import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
}

const availableFilterDefault = {
    "startDate": addDays(new Date(), new Date().getHours() >= 21 ? 1 : 0),
    "endDate": addDays(new Date(), new Date().getHours() >= 21 ? 1 : 0),
}

const AvailableFilterView = ({ filter, setFilter }) => {

    const textContext = useContext(LanguageContext);
    const [formData, setFormData] = useState({ ...filter })

    const _submitForm = () => setFilter(formData)

    return (
        <Card width="12" flex={true} shadow={false} justify="center" align="center" color="colorGreenLight" px="16" radius="18px">
            <ContainerFlex width="12" justify="center" textAlign="center" fontWeight="bold" fontSize="23px" ph="32" pv="16">
                <TextLocalized children="msgAvailableFilter" />
            </ContainerFlex>

            <Container width="12" lg="6" ph="16">
                <Card width="12" color="colorWhite" mv="8" px="16" fontColor="colorBlack" fontSize="18px">
                    <DatePicker
                        locale="es"
                        dateFormat="dd/MM/yyyy"
                        showPopperArrow={false}
                        selected={formData.startDate}
                        onChange={(date) => setFormData({ ...formData, startDate: date, endDate: date })}
                        name="startDate"
                        placeholderText={textContext.dictionary['msgDate']}
                        className="input-date"
                    />
                </Card>
            </Container>

            <Container width="12" lg="6" px="16">
                <Button width="12" onclick={_submitForm} color="colorGreen" fontColor="colorWhite" textAlign="center" fontWeight="bold" ph="32" pv="16" children={<TextLocalized children="actionFilter" />} />
            </Container>

        </Card>
    );
}

const AvailableItem = ({ item, available }) => {
    const textContext = useContext(LanguageContext);

    return <Container ph="16" width="12" lg="12">
        <Card shadow={false} flex={true} width="12" align="center" textAlign="center" color="colorBlueLight" mb="16" pv="16">
            <Container fontWeight="bold" width="12" lg="4" pv="8" ph="16" children={`${textContext.dictionary["msgRow"]}: ${item.row}`} />
            <Container fontWeight="bold" width="12" lg="4" pv="8" ph="16" children={`${item.count} ${textContext.dictionary["msgHamacas"]}`} />
            <Container fontWeight="bold" width="12" lg="4" pv="8" ph="16" children={`${available} ${textContext.dictionary["msgAvailables"]}`} />
        </Card>
    </Container>
}

const AvailableListView = ({ item }) => {
    return <ContainerFlex width="12" justify="center">
        <ContainerFlex width="12" justify="center" fontWeight="bold" fontSize="25px" fontSizeLg="35px" mv="64" textAlign="center">
            <TextLocalized children="msgAvailable" />
        </ContainerFlex>
        {
            item ?
                item.location.vacancy.map((vacancy, index) => <AvailableItem key={index} item={vacancy} available={item.available[`${vacancy.row}`]} />)
                : null
        }
    </ContainerFlex>
}

const AvailableView = () => {

    const [item, setItem] = useState()
    const [filter, setFilter] = useState(availableFilterDefault);

    useEffect(() => {
        const requestAPI = () => {

            let filterAux = "";

            console.log(filter.startDate)
            filterAux += `startDate=${filter.startDate.setHours(0,0,0,0)}&`;
            filterAux += `endDate=${filter.endDate.setHours(23,59,59,0)}&`;

            //Location default
            filterAux += `location=1`;

            HttpConnection(`${HttpOperations.available}${filterAux}`, (item) => {
                setItem(item);
            }, (error) => console.log(error), HttpMethod.get)
        }
        requestAPI();
    }, [filter])

    return (
        <ContainerFlex width="10" lg="9" pv="32" align="center" justify="space-between">
            <AvailableFilterView filter={filter} setFilter={setFilter} />
            <AvailableListView item={item} />
        </ContainerFlex>
    );
};

export default AvailableView;