import React, { useEffect } from 'react';
import { Card, TextLocalized, ContainerFlex } from '../styled/Syled';

export const notificationDefault = {
    show: false,
    color: "colorRed",
    message: ""
}

export const NotificationView = ({ noti, setNoti }) => {

    useEffect(() => {
        let timer = setTimeout(() => {
            setNoti({ ...noti, show: false });
        }, 3000)
        return () => clearTimeout(timer);
    })

    return noti.show ? <ContainerFlex width="12" justify="center" ph="16" mt="32">
        <Card px="16" fontColor="colorWhite" textAlign="center" color={noti.color}
            children={<TextLocalized children={noti.message} />} />
    </ContainerFlex>
        : null

}